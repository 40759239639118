import React, { useRef, useEffect } from "react"
import gsap from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"
import { Trans } from "@lingui/macro"
import AcrSvg from "../share/AcrSvg"
import { connect } from "react-redux"

const FirstPart = ({ scrolltriggerReady }) => {
  let rotateEl = useRef()

  //tweens
  let rotationTween = useRef(null)

  const circleAnimation = () => {
    var rotateDuration = 30
    var rotate = gsap.to(rotateEl, {
      rotation: 360,
      duration: rotateDuration,
      onReverseComplete() {
        this.totalTime(rotateDuration * 100) // loop in reverse
      },
      repeat: -1,
      ease: "none",
    })

    var clamp = gsap.utils.clamp(-100, 100)
    ScrollTrigger.getById("contact-rotator") &&
      ScrollTrigger.getById("contact-rotator").kill()
    rotationTween.current = ScrollTrigger.create({
      id: "contact-rotator",
      onUpdate: self => {
        rotate.timeScale(clamp(self.getVelocity() / 100))
        gsap.to(rotate, {
          timeScale: 1,
          duration: 2,
          overwrite: true,
          ease: "power1.inOut",
        })
      },
    })
  }

  useEffect(() => {
    if (scrolltriggerReady) {
      circleAnimation()
    }
  }, [scrolltriggerReady])

  useEffect(() => {
    return () => {
      rotationTween.current && rotationTween.current.kill()
    }
  }, [])

  return (
    <div className="first-section">
      <div className=" contacts-small-screen d-block d-sm-none">
        <div className="row">
          <div className="main-title col-10 offset-1">
            <Trans>
              Contactez-nous et nous nous ferons un plaisir de vous aider.
            </Trans>
          </div>
        </div>
        <div className="row">
          <div className="logo col-5">
            <AcrSvg color="#231f20" />
          </div>
          <div className="contacts col-7">
            <div className="contact">
              <div className="title">
                <Trans>Addresse</Trans>
              </div>
              <div className="text">
                <div>25 rue Rabelais</div>
                <div>37400 Amboise</div>
                <div>France</div>
              </div>
            </div>
            <div className="contact">
              <div className="title">
                <Trans>Contact info</Trans>
              </div>
              <div className="text">
                <div>+33 (0)2 47 57 53 84</div>
                <div>info@hotel-acr.com</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="div-svg row">
        <div className=" col-sm-9 col-12">
          <div className="svg-container text-center">
            <svg
              ref={el => (rotateEl = el)}
              version="1.1"
              id="Layer_1"
              xmlns="http://www.w3.org/2000/svg"
              xmlnsXlink="http://www.w3.org/1999/xlink"
              x="0px"
              y="0px"
              viewBox="0 0 1280 1280"
              enableBackground="new 0 0 1280 1280"
              xmlSpace="preserve"
            >
              <path
                id="SVGID_x5F_1_x5F_"
                fill="none"
                d="M640,291.61c192.41,0,348.39,155.98,348.39,348.39S832.41,988.39,640,988.39
                            S291.61,832.41,291.61,640S447.59,291.61,640,291.61z"
              />
              <text>
                <textPath xlinkHref="#SVGID_x5F_1_x5F_" startOffset="1.1312%">
                  <tspan fontFamily="'Italiana', serif" fontSize="150px">
                    <Trans>ENTREZ EN CONTACT AVEC NOUS</Trans>
                  </tspan>
                </textPath>
              </text>
              {/* <text>Un plaisir de vous aider</text> */}
            </svg>
          </div>
        </div>
        <div className="contact-infos col-sm-3 d-none d-sm-block">
          <div className="logo">
            <AcrSvg color="#231f20" />
          </div>
          <div className="contact-box">
            <div className="title">
              <Trans>Addresse</Trans>
            </div>
            <div className="infos">
              <div>25 rue Rabelais</div>
              <div>37400 Amboise</div>
            </div>
          </div>
          <div className="contact-box">
            <div className="title">
              <Trans>Contact info</Trans>
            </div>
            <div className="infos">
              <div>+33 (0)2 47 57 53 84</div>
              <div>info@hotel-acr.com</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = ({ scrolltriggerReady }) => {
  return { scrolltriggerReady }
}

export default connect(mapStateToProps, null)(FirstPart)
