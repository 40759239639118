import React, { useRef, useEffect, useState } from "react"
import { Image } from "../gatsby-images/image"
import gsap from "gsap"
import { connect } from "react-redux"
import { ScrollTrigger } from "gsap/ScrollTrigger"
import { Trans } from "@lingui/macro"
import axios from "axios"
import * as qs from "query-string"

function validate(values) {
  let errors = {}
  if (!values.email) {
    errors.email = "Email address is required"
  } else if (!/\S+@\S+\.\S+/.test(values.email)) {
    errors.email = "Email address is invalid"
  }
  return errors
}

const inputsLang = {
  en: {
    name: "NAME*",
    email: "EMAIL*",
    phone: "PHONE",
    objet: "OBJECT",
    message: "Your message...",
  },
  fr: {
    name: "NOM*",
    email: "EMAIL*",
    phone: "TÉLÉPHONE",
    objet: "OBJET",
    message: "Votre message...",
  },
  ru: {
    name: "ФАМИЛИЯ*",
    email: "EMAIL*",
    phone: "ТЕЛЕФОН",
    objet: "ТЕМА",
    message: "Ваше сообщение...",
  },
}

const ContactFormSection = ({ scrolltriggerReady, language }) => {
  let form = useRef()
  let mainContainer = useRef()
  let formRefs = useRef([])
  const [success, setSucess] = useState(false)

  //tweens
  let formTween = useRef(null)

  const addToFormRefs = el => {
    if (el && !formRefs.current.includes(el)) {
      formRefs.current.push(el)
    }
  }

  const submitForm = e => {
    e.preventDefault()

    const formData = {}

    formRefs.current.map(key => (formData[key.name] = key.value))
    const axiosOptions = {
      url: window.location.pathname,
      method: "post",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      data: qs.stringify(formData),
    }

    axios(axiosOptions)
      .then(response => {
        formRefs.current.map(el => (el.value = ""))
        setSucess(true)
      })
      .catch(err => console.log(err, "error submitting form"))

    setTimeout(() => {
      setSucess(false)
    }, 5000)
  }

  useEffect(() => {
    if (scrolltriggerReady) {
      gsap.set(form, { yPercent: 0 })
      ScrollTrigger.getById("contact-form") &&
        ScrollTrigger.getById("contact-form").kill()
      formTween.current = gsap.fromTo(
        form,
        { yPercent: 0, xPercent: -15 },
        {
          scrollTrigger: {
            trigger: mainContainer,
            start: "top 100%",
            end: "bottom 50%",
            scrub: true,
          },
          yPercent: 5,
          xPercent: 0,
        }
      )
    }
  }, [scrolltriggerReady])

  useEffect(() => {
    return () => {
      formTween.current && formTween.current.kill()
    }
  }, [])

  return (
    <div className="contact-form-section" ref={el => (mainContainer = el)}>
      <div className="form" ref={el => (form = el)}>
        <div className="title">
          <Trans>Envoyez-nous un message</Trans>
        </div>
        <form
          onSubmit={e => {
            submitForm(e)
          }}
          method="POST"
          netlify-honeypot="bot-field"
          data-netlify="true"
          name="contact"
        >
          <input type="hidden" name="bot-field" ref={addToFormRefs} />
          <input
            type="hidden"
            name="form-name"
            value="contact"
            ref={addToFormRefs}
          />
          {/* <label for='name'> <Trans>NOM*</Trans> </label> */}
          <input
            type="text"
            name="name"
            id="name"
            placeholder={inputsLang[language]["name"]}
            required
            ref={addToFormRefs}
          />
          <input
            type="email"
            name="email"
            id="email"
            placeholder={inputsLang[language]["email"]}
            required
            ref={addToFormRefs}
          />
          <input
            type="number"
            name="phone"
            id="phone"
            placeholder={inputsLang[language]["phone"]}
            ref={addToFormRefs}
          />
          <input
            type="text"
            name="subject"
            id="subject"
            placeholder={inputsLang[language]["objet"]}
            required
            ref={addToFormRefs}
          />
          <textarea
            name="message"
            id="message"
            rows="5"
            placeholder={inputsLang[language]["message"]}
            required
            ref={addToFormRefs}
          />
          <button type="submit">
            <Trans>Envoyer</Trans>
          </button>
        </form>
        <div className="message-area">
          {success && (
            <p className="success">
              <Trans>Merci ! Nous vous contacterons au plus vite :)</Trans>
            </p>
          )}
        </div>
      </div>
      <div className="image">
        <div style={{ width: "100%", height: "100%" }}></div>
        <Image src="facade-acr" />
      </div>
    </div>
  )
}

const mapStateToProps = ({ scrolltriggerReady, language }) => {
  return { scrolltriggerReady, language }
}

export default connect(mapStateToProps, null)(ContactFormSection)
