import React, { useEffect, useState, useRef } from "react"
import FirstSection from "../components/contactpage/FirstSection"
import ContactFormSection from "../components/contactpage/ContactFormSection"
import Newsletters from "../components/homepage/newsletter/Newsletter"
import { connect } from "react-redux"
import SEO from "../components/seo"
import { ScrollTrigger } from "gsap/ScrollTrigger"
import { console } from "globalthis/implementation"

const langSeo = {
  fr: {
    title: "Hôtel Au Charme Rabelaisien **** | Contact",
    description:
      "Hôtel Au Charme Rabelaisien, en plein centre d'Amboise. N'hésitez pas à nous contactez directement pour bénéficier de tarifs préférentiels",
  },
  en: {
    title: "Hôtel Au Charme Rabelaisien **** | Contact",
    description:
      "Au Charme Rabelaisien hotel, in the center of Amboise. Do not hesitate to contact us directly to benefit from preferential rates",
  },
  ru: {
    title: "Hôtel Au Charme Rabelaisien **** | Contact",
    description:
      "Au Charme Rabelaisien hotel, in the center of Amboise. Do not hesitate to contact us directly to benefit from preferential rates",
  },
}

const Contact = ({
  updateNavColor,
  language,
  scrollOffset,
  updateShowArrowDown,
  scrolltriggerReady,
}) => {
  let urlLanguage = "fr"

  if (typeof window !== "undefined") {
    const url = window.location.pathname

    if (url.includes("fr")) {
      urlLanguage = "fr"
    } else {
      urlLanguage = "en"
    }
  }
  //show arrow down
  let tempScrollTriggerContactRef = useRef(null)
  //state
  const [pageHeight, setPageHeight] = useState("")

  useEffect(() => {
    if (scrolltriggerReady) {
      if (pageHeight && pageHeight - scrollOffset < window.innerHeight + 100) {
        updateShowArrowDown(false)
      } else {
        updateShowArrowDown(true)
      }
    }
  }, [scrollOffset, scrolltriggerReady])

  useEffect(() => {
    updateNavColor()
    const tempScrollTrigger = ScrollTrigger.create({ id: "temp-contact" })
    setPageHeight(tempScrollTrigger["scroller"]["scrollHeight"])
    tempScrollTriggerContactRef.current = tempScrollTrigger

    return () => {
      tempScrollTriggerContactRef.current &&
        tempScrollTriggerContactRef.current.kill()
    }
  }, [])
  return (
    <div className="contact-page">
      <SEO
        title={langSeo[urlLanguage]["title"]}
        description={langSeo[urlLanguage]["description"]}
      />
      <FirstSection />
      <ContactFormSection />
      <Newsletters bgColor={"black"} />
    </div>
  )
}

const mapStateToProps = ({
  language,
  scrollOffset,
  updateShowArrowDown,
  scrolltriggerReady,
}) => {
  return { language, scrollOffset, updateShowArrowDown, scrolltriggerReady }
}

const mapDispatchToProps = dispatch => {
  return {
    updateNavColor: () =>
      dispatch({ type: `UPDATE_NAV_COLOR`, payload: "black" }),
    updateShowArrowDown: bool =>
      dispatch({ type: "UPDATE_SHOW_ARROW_DOWN", payload: bool }),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Contact)
